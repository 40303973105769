import React from 'react';
import Topbar from '../layouts/Topbar'
import SubHeader from '../layouts/SubHeader'

function Visiting() {
    return (
        <>
            <Topbar />
            <SubHeader />
            <main>
                <div className="grid-container">

                    <div className="row">
                        <div className="col-12 ">

                            <ul className="breadcrumbs" role="navigation">
                                <li><a href="/">Home</a></li>
                                <li>Visiting Cards</li>
                            </ul>

                        </div>

                    </div>
                </div>

                {/* middle section with images and descriptions */}


                <div className="container">

                    <div className="row" style={{position:'relative'}}>

                        <div className="col-md-5">

                            <div id="demo" className="carousel imageSlide slide" data-ride="carousel">
                                {/* Indicators */}
                                <ul className="carousel-indicators">
                                    <li data-target="#demo" data-slide-to={0} className="active" />
                                    <li data-target="#demo" data-slide-to={1} />
                                    <li data-target="#demo" data-slide-to={2} />
                                </ul>
                                {/* The slideshow */}
                                <div className="carousel-inner" style={{width :'470px'}}>
                                    <div className="carousel-item active">
                                    </div>
                                    <div className="carousel-item">
                                    </div>
                                    <div className="carousel-item">
                                    </div>
                                </div>
                                {/* Left and right controls */}
                                <a className="carousel-control-prev" href="#demo" data-slide="prev">
                                    <span className="carousel-control-prev-icon" />
                                </a>
                                <a className="carousel-control-next" href="#demo" data-slide="next">
                                    <span className="carousel-control-next-icon" />
                                </a>
                            </div>

                        </div>

                        <div className="col-md-7">
                            <div className="title-block">
                                <h1 className="title-block-title text-size-1 strong generic-header-title-container">
                                    Standard Visiting Cards
                                </h1>
                            </div>

                            <div className="standard-section standard-section-mini short-description-container">
                                <div className="short-description-body text-size-5">
                                    <p><strong>Personalized cards with a professional look.</strong><br />
                                        •<span> </span>Dimensions: 89 x 51 mm<br />
                                        •<span> </span>4000+ design options available<br />
                                        •<span> </span>Standard glossy or matte paper included<br />
                                        <br />
                                        </p>
                                </div>
                            </div>



                            <div className="product-configurator js-product-configurator" data-configurator-version="i4">
                                <div className="variant-attributes">
                                    <div className="shadow-container variant-attribute-group js-variant-attribute-group">
                                        
                                        <div className="standard-section standard-section-mini variant-attribute js-variant-attribute js-hide-incompatible-choices " data-variant-is-mandatory="true" variant-name="Attribute-233857" data-variant-attribute-id="Attribute-233857" data-variant-attribute-name="Paper stock" data-variant-type="Material">
                                            <div className="variant-attribute-label more-info-icon-label">
                                                <span className="variant-attribute-name">Paper stock</span>
                                            </div>
                                            <div className="variant-attribute-select js-variant-attribute-select">
                                                <div className="js-configurator-error configurator-error configurator-error-complex">
                                                    <div className="configurator-error-message js-configurator-error-message" />
                                                    <span className="configurator-error-ok js-configurator-error-ok"><button className="textbutton textbutton-skin-promo-bright-blue">Ok</button></span>
                                                    <a className="configurator-error-unwind js-configurator-error-unwind" />
                                                    <span className="configurator-error-dismiss-button js-configurator-error-dismiss-button"><span className="graphic-button graphic-button-delete graphic-button-skin-black" role="button" /></span>
                                                </div>
                                                <div className="option-set option-set-skin-buttons-wide" name="Attribute-233857">
                                                    <div className="js-variant-attribute-choice option-set-option-wrapper js-default-choice option-set-option-unavailable option-set-option-hidden" data-choice-id={238821} data-choice-name="Matte" data-sku htmlFor={238821} aria-checked="false" data-invalid-type="Incompatible">
                                                        <span className="js-variant-attribute-choice-radio option-set-option stylized-radio"><input className="stylized-radio-input " id={238821} name="Attribute-233857" type="radio" defaultValue={238821} /><label htmlFor={238821} className="option-set-option-unavailable option-set-option-hidden" data-invalid-type="Incompatible" /></span>
                                                        <div className="option-set-contents">
                                                            Matte
                                                        </div>
                                                    </div>
                                                    <div className="js-variant-attribute-choice option-set-option-wrapper option-set-option-wrapper-with-callout" data-choice-id={238822} data-choice-name="Glossy" data-sku htmlFor={238822} aria-checked="false">
                                                        <span className="js-variant-attribute-choice-radio option-set-option stylized-radio"><input className="stylized-radio-input " id={238822} name="Attribute-233857" type="radio" defaultValue={238822} /><label htmlFor={238822} /></span>
                                                        <div className="option-set-contents">
                                                            Glossy
                                                        </div>
                                                    </div>
                                                    <div className="js-variant-attribute-choice option-set-option-wrapper option-set-option-hidden-even-if-compatible option-set-option-unavailable option-set-option-hidden" data-choice-id={238831} data-choice-name="Real White" data-sku htmlFor={238831} aria-checked="false" data-invalid-type="Incompatible">
                                                        <span className="js-variant-attribute-choice-radio option-set-option stylized-radio"><input className="stylized-radio-input " id={238831} name="Attribute-233857" type="radio" defaultValue={238831} /><label htmlFor={238831} className="option-set-option-unavailable option-set-option-hidden" data-invalid-type="Incompatible" /></span>
                                                        <div className="option-set-contents">
                                                            Real White
                                                        </div>
                                                    </div>
                                                    <div className="js-variant-attribute-choice option-set-option-wrapper option-set-option-hidden-even-if-compatible option-set-option-unavailable option-set-option-hidden" data-choice-id={238844} data-choice-name="Velvet touch" data-sku htmlFor={238844} aria-checked="false" data-invalid-type="Incompatible">
                                                        <span className="js-variant-attribute-choice-radio option-set-option stylized-radio"><input className="stylized-radio-input " id={238844} name="Attribute-233857" type="radio" defaultValue={238844} /><label htmlFor={238844} className="option-set-option-unavailable option-set-option-hidden" data-invalid-type="Incompatible" /></span>
                                                        <div className="option-set-contents">
                                                            Velvet touch
                                                        </div>
                                                    </div>
                                                    <div className="js-variant-attribute-choice option-set-option-wrapper option-set-option-hidden-even-if-compatible option-set-option-unavailable option-set-option-hidden" data-choice-id={357642} data-choice-name="Non-tearable" data-sku htmlFor={357642} aria-checked="false" data-invalid-type="Incompatible">
                                                        <span className="js-variant-attribute-choice-radio option-set-option stylized-radio"><input className="stylized-radio-input " id={357642} name="Attribute-233857" type="radio" defaultValue={357642} /><label htmlFor={357642} className="option-set-option-unavailable option-set-option-hidden" data-invalid-type="Incompatible" /></span>
                                                        <div className="option-set-contents">
                                                            Non-tearable
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="shadow-container variant-attribute-group js-variant-attribute-group" style={{ display: 'none' }}>
                                        <div className="standard-section standard-section-mini variant-attribute js-variant-attribute js-hide-incompatible-choices variant-attribute-invisible js-variant-attribute-invisible" data-variant-is-mandatory="false" variant-name="Attribute-233878" data-variant-attribute-id="Attribute-233878" data-variant-attribute-name="Finish" data-variant-type>
                                            <div className="variant-attribute-label more-info-icon-label">
                                                <span className="variant-attribute-name">Finish</span>
                                            </div>
                                            <div className="variant-attribute-select js-variant-attribute-select">
                                                <div className="js-configurator-error configurator-error configurator-error-complex">
                                                    <div className="configurator-error-message js-configurator-error-message" />
                                                    <span className="configurator-error-ok js-configurator-error-ok"><button className="textbutton textbutton-skin-promo-bright-blue">Ok</button></span>
                                                    <a className="configurator-error-unwind js-configurator-error-unwind" />
                                                    <span className="configurator-error-dismiss-button js-configurator-error-dismiss-button"><span className="graphic-button graphic-button-delete graphic-button-skin-black" role="button" /></span>
                                                </div>
                                                <div className="option-set option-set-skin-buttons-wide" name="Attribute-233878">
                                                    <div className="js-variant-attribute-choice option-set-option-wrapper js-default-choice option-set-option-hidden-even-if-compatible" data-choice-id={233824} data-choice-name="None" data-sku htmlFor={233824} aria-checked="false">
                                                        <span className="js-variant-attribute-choice-radio option-set-option stylized-radio"><input className="stylized-radio-input " id={233824} name="Attribute-233878" type="radio" defaultValue={233824} /><label htmlFor={233824} /></span>
                                                        <div className="option-set-contents">
                                                            None
                                                        </div>
                                                    </div>
                                                    <div className="js-variant-attribute-choice option-set-option-wrapper option-set-option-hidden-even-if-compatible option-set-option-unavailable option-set-option-hidden" data-choice-id={233826} data-choice-name="Spot UV" data-sku htmlFor={233826} aria-checked="false" data-invalid-type="Incompatible">
                                                        <span className="js-variant-attribute-choice-radio option-set-option stylized-radio"><input className="stylized-radio-input " id={233826} name="Attribute-233878" type="radio" defaultValue={233826} /><label htmlFor={233826} className="option-set-option-unavailable option-set-option-hidden" data-invalid-type="Incompatible" /></span>
                                                        <div className="option-set-contents">
                                                            Spot UV
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="shadow-container variant-attribute-group js-variant-attribute-group">
                                        <div className="standard-section standard-section-mini variant-attribute js-variant-attribute  " data-variant-is-mandatory="true" variant-name="Attribute-233885" data-variant-attribute-id="Attribute-233885" data-variant-attribute-name="Corners" data-variant-type>
                                            <div className="variant-attribute-label more-info-icon-label">
                                                <span className="variant-attribute-name">Corners</span>
                                            </div>
                                            <div className="variant-attribute-select js-variant-attribute-select">
                                                <div className="js-configurator-error configurator-error configurator-error-complex">
                                                    <div className="configurator-error-message js-configurator-error-message" />
                                                    <span className="configurator-error-ok js-configurator-error-ok"><button className="textbutton textbutton-skin-promo-bright-blue">Ok</button></span>
                                                    <a className="configurator-error-unwind js-configurator-error-unwind" />
                                                    <span className="configurator-error-dismiss-button js-configurator-error-dismiss-button"><span className="graphic-button graphic-button-delete graphic-button-skin-black" role="button" /></span>
                                                </div>
                                                <div className="option-set option-set-skin-buttons-wide" name="Attribute-233885">
                                                    <div className="js-variant-attribute-choice option-set-option-wrapper js-default-choice" data-choice-id={233838} data-choice-name="Standard" data-sku htmlFor={233838} aria-checked="false">
                                                        <span className="js-variant-attribute-choice-radio option-set-option stylized-radio"><input className="stylized-radio-input " id={233838} name="Attribute-233885" type="radio" defaultValue={233838} /><label htmlFor={233838} /></span>
                                                        <div className="option-set-contents">
                                                            Standard
                                                        </div>
                                                    </div>
                                                    <div className="js-variant-attribute-choice option-set-option-wrapper" data-choice-id={233840} data-choice-name="Rounded" data-sku htmlFor={233840} aria-checked="false">
                                                        <span className="js-variant-attribute-choice-radio option-set-option stylized-radio"><input className="stylized-radio-input " id={233840} name="Attribute-233885" type="radio" defaultValue={233840} /><label htmlFor={233840} /></span>
                                                        <div className="option-set-contents">
                                                            Rounded
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="shadow-container variant-attribute-group js-variant-attribute-group" style={{ display: 'none' }}>
                                        <div className="standard-section standard-section-mini variant-attribute js-variant-attribute  variant-attribute-invisible js-variant-attribute-invisible" data-variant-is-mandatory="false" variant-name="Attribute-233893" data-variant-attribute-id="Attribute-233893" data-variant-attribute-name="Size" data-variant-type="Size">
                                            <div className="variant-attribute-label more-info-icon-label">
                                                <span>Size</span>
                                            </div>
                                            <div className="variant-attribute-select js-variant-attribute-select">
                                                <div className="js-configurator-error configurator-error configurator-error-complex">
                                                    <div className="configurator-error-message js-configurator-error-message" />
                                                    <span className="configurator-error-ok js-configurator-error-ok"><button className="textbutton textbutton-skin-promo-bright-blue">Ok</button></span>
                                                    <a className="configurator-error-unwind js-configurator-error-unwind" />
                                                    <span className="configurator-error-dismiss-button js-configurator-error-dismiss-button"><span className="graphic-button graphic-button-delete graphic-button-skin-black" role="button" /></span>
                                                </div>
                                                <div className="option-set option-set-skin-buttons" name="Attribute-233893">
                                                    <div className="js-variant-attribute-choice option-set-option-wrapper js-default-choice" data-choice-id={233847} data-choice-name="Standard (3.5&quot; x 2&quot;)" data-sku htmlFor={233847} aria-checked="false">
                                                        <span className="js-variant-attribute-choice-radio option-set-option stylized-radio"><input className="stylized-radio-input " id={233847} name="Attribute-233893" type="radio" defaultValue={233847} /><label htmlFor={233847} /></span>
                                                        <div className="option-set-contents">
                                                            Standard (3.5" x 2")
                                                        </div>
                                                    </div>
                                                    <div className="js-variant-attribute-choice option-set-option-wrapper option-set-option-unavailable" data-choice-id={233850} data-choice-name="Square (2.5&quot; x 2.5&quot;)" data-sku htmlFor={233850} aria-checked="false" data-invalid-type="Incompatible">
                                                        <span className="js-variant-attribute-choice-radio option-set-option stylized-radio"><input className="stylized-radio-input " id={233850} name="Attribute-233893" type="radio" defaultValue={233850} /><label htmlFor={233850} className="option-set-option-unavailable" data-invalid-type="Incompatible" /></span>
                                                        <div className="option-set-contents">
                                                            Square (2.5" x 2.5")
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="shadow-container variant-attribute-group js-variant-attribute-group" style={{ display: 'none' }}>
                                        <div className="standard-section standard-section-mini variant-attribute js-variant-attribute  variant-attribute-invisible js-variant-attribute-invisible" data-variant-is-mandatory="false" variant-name="ProductOrientation" data-variant-attribute-id="ProductOrientation" data-variant-attribute-name="Orientation" data-variant-type="Orientation">
                                            <div className="variant-attribute-label more-info-icon-label">
                                                <span className="variant-attribute-name">Orientation</span>
                                            </div>
                                            <div className="variant-attribute-select js-variant-attribute-select">
                                                <div className="js-configurator-error configurator-error configurator-error-complex">
                                                    <div className="configurator-error-message js-configurator-error-message" />
                                                    <span className="configurator-error-ok js-configurator-error-ok"><button className="textbutton textbutton-skin-promo-bright-blue">Ok</button></span>
                                                    <a className="configurator-error-unwind js-configurator-error-unwind" />
                                                    <span className="configurator-error-dismiss-button js-configurator-error-dismiss-button"><span className="graphic-button graphic-button-delete graphic-button-skin-black" role="button" /></span>
                                                </div>
                                                <div className="option-set option-set-skin-buttons-wide" name="ProductOrientation">
                                                    <div className="js-variant-attribute-choice option-set-option-wrapper js-default-choice" data-choice-id={106926} data-choice-name="Horizontal" data-sku htmlFor={106926} aria-checked="false">
                                                        <span className="js-variant-attribute-choice-radio option-set-option stylized-radio"><input className="stylized-radio-input " id={106926} name="ProductOrientation" type="radio" defaultValue={106926} /><label htmlFor={106926} /></span>
                                                        <div className="option-set-contents">
                                                            Horizontal
                                                        </div>
                                                    </div>
                                                    <div className="js-variant-attribute-choice option-set-option-wrapper" data-choice-id={106927} data-choice-name="Vertical" data-sku htmlFor={106927} aria-checked="false">
                                                        <span className="js-variant-attribute-choice-radio option-set-option stylized-radio"><input className="stylized-radio-input " id={106927} name="ProductOrientation" type="radio" defaultValue={106927} /><label htmlFor={106927} /></span>
                                                        <div className="option-set-contents">
                                                            Vertical
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                               
                            </div>


                        </div>
                    </div>
                </div>
            </main>

        </>
    );
}

export default Visiting;
