import React from 'react';
import Topbar from '../layouts/Topbar'
import SubHeader from '../layouts/SubHeader'

function Pens() {
    return (
        <>

            <Topbar />
            <SubHeader />



            <main>
                <div className="grid-container">

                    <div className="row">
                        <div className="col-12 ">

                            <ul className="breadcrumbs" role="navigation">
                                <li><a href="/">Home</a></li>
                                <li>Pens</li>
                            </ul>

                        </div>

                    </div>
                </div>
                <div className="grid-container">

                    <div className="row">
                        <div className="col-12 ">
                            <div className="title-block">
                                <h1 className="title-block-title text-size-1 strong ">Personalised Pens</h1>
                            </div>

                        </div>

                    </div>
                </div>
                <div className="container">

                    <div className="row mt-5">

                        <div className="col-md-4 mb-4">



                            <section>

                                <h5>Stationery</h5>

                                <div className="text-muted small text-uppercase mb-5">


                                    <p className="mb-3"><a className="card-link-secondary">Letterheads</a></p>
                                    <p className="mb-3"><a className="card-link-secondary">Notebooks</a></p>
                                    <p className="mb-3"><a className="card-link-secondary">Envelops</a></p>
                                    <p className="mb-3"><a className="card-link-secondary">Envelope Seals</a></p>
                                    <p className="mb-3"><a className="card-link-secondary">Bill Books</a></p>
                                </div>

                            </section>








                        </div>

                        <div className="col-md-8 mb-4">



                            <section>


                                <div id="loader" className="loader-overlay">
                                    <div className="preloader-wrapper active">
                                        <div className="spinner-layer spinner-blue-only">
                                            <div className="circle-clipper left">
                                                <div className="circle"></div>
                                            </div>
                                            <div className="gap-patch">
                                                <div className="circle"></div>
                                            </div>
                                            <div className="circle-clipper right">
                                                <div className="circle"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="row" id="products">
                                    <div class="col-md-4 mb-5">
                                        <div>
                                            <div className="view zoom overlay rounded z-depth-2">
                                                <img class="img-fluid w-100" src="https://cms.cloudinary.vpsvc.com//image/fetch/q_auto:eco,w_700,f_auto,dpr_auto/https://s3-eu-west-1.amazonaws.com/sitecore-media-bucket/prod%2fen%2f%7b24732AAE-C173-4364-9291-6B7DF79F08A8%7d%3fv%3db98fd032335a551e7f0d041919948606" alt="Sample" />
                                                <a href="#!">
                                                    <div className="mask">
                                                        <img className="img-fluid w-100" src="https://cms.cloudinary.vpsvc.com//image/fetch/q_auto:eco,w_700,f_auto,dpr_auto/https://s3-eu-west-1.amazonaws.com/sitecore-media-bucket/prod%2fen%2f%7b24732AAE-C173-4364-9291-6B7DF79F08A8%7d%3fv%3db98fd032335a551e7f0d041919948606" />
                                                        <div className="mask rgba-black-slight"></div>
                                                    </div>
                                                </a>
                                            </div>
                                            <div class="text-center pt-4">
                                                <h5>Fantasy T-shirt</h5>
                                                <p><span class="mr-1"><strong>$12.99</strong></span></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4 mb-5">
                                        <div>
                                            <div className="view zoom overlay rounded z-depth-2">
                                                <img class="img-fluid w-100" src="https://cms.cloudinary.vpsvc.com//image/fetch/q_auto:eco,w_700,f_auto,dpr_auto/https://s3-eu-west-1.amazonaws.com/sitecore-media-bucket/prod%2fen%2f%7b24732AAE-C173-4364-9291-6B7DF79F08A8%7d%3fv%3db98fd032335a551e7f0d041919948606" alt="Sample" />
                                                <a href="#!">
                                                    <div className="mask">
                                                        <img className="img-fluid w-100" src="https://cms.cloudinary.vpsvc.com//image/fetch/q_auto:eco,w_700,f_auto,dpr_auto/https://s3-eu-west-1.amazonaws.com/sitecore-media-bucket/prod%2fen%2f%7b24732AAE-C173-4364-9291-6B7DF79F08A8%7d%3fv%3db98fd032335a551e7f0d041919948606" />
                                                        <div className="mask rgba-black-slight"></div>
                                                    </div>
                                                </a>
                                            </div>
                                            <div class="text-center pt-4">
                                                <h5>Fantasy T-shirt</h5>
                                                <p><span class="mr-1"><strong>$12.99</strong></span></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4 mb-5">
                                        <div>
                                            <div className="view zoom overlay rounded z-depth-2">
                                                <img class="img-fluid w-100" src="https://cms.cloudinary.vpsvc.com//image/fetch/q_auto:eco,w_700,f_auto,dpr_auto/https://s3-eu-west-1.amazonaws.com/sitecore-media-bucket/prod%2fen%2f%7b24732AAE-C173-4364-9291-6B7DF79F08A8%7d%3fv%3db98fd032335a551e7f0d041919948606" alt="Sample" />
                                                <a href="#!">
                                                    <div className="mask">
                                                        <img className="img-fluid w-100" src="https://cms.cloudinary.vpsvc.com//image/fetch/q_auto:eco,w_700,f_auto,dpr_auto/https://s3-eu-west-1.amazonaws.com/sitecore-media-bucket/prod%2fen%2f%7b24732AAE-C173-4364-9291-6B7DF79F08A8%7d%3fv%3db98fd032335a551e7f0d041919948606" />
                                                        <div className="mask rgba-black-slight"></div>
                                                    </div>
                                                </a>
                                            </div>
                                            <div class="text-center pt-4">
                                                <h5>Fantasy T-shirt</h5>
                                                <p><span class="mr-1"><strong>$12.99</strong></span></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4 mb-5">
                                        <div>
                                            <div className="view zoom overlay rounded z-depth-2">
                                                <img class="img-fluid w-100" src="https://cms.cloudinary.vpsvc.com//image/fetch/q_auto:eco,w_700,f_auto,dpr_auto/https://s3-eu-west-1.amazonaws.com/sitecore-media-bucket/prod%2fen%2f%7b24732AAE-C173-4364-9291-6B7DF79F08A8%7d%3fv%3db98fd032335a551e7f0d041919948606" alt="Sample" />
                                                <a href="#!">
                                                    <div className="mask">
                                                        <img className="img-fluid w-100" src="https://cms.cloudinary.vpsvc.com//image/fetch/q_auto:eco,w_700,f_auto,dpr_auto/https://s3-eu-west-1.amazonaws.com/sitecore-media-bucket/prod%2fen%2f%7b24732AAE-C173-4364-9291-6B7DF79F08A8%7d%3fv%3db98fd032335a551e7f0d041919948606" />
                                                        <div className="mask rgba-black-slight"></div>
                                                    </div>
                                                </a>
                                            </div>
                                            <div class="text-center pt-4">
                                                <h5>Fantasy T-shirt</h5>
                                                <p><span class="mr-1"><strong>$12.99</strong></span></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4 mb-5">
                                        <div>
                                            <div className="view zoom overlay rounded z-depth-2">
                                                <img class="img-fluid w-100" src="https://cms.cloudinary.vpsvc.com//image/fetch/q_auto:eco,w_700,f_auto,dpr_auto/https://s3-eu-west-1.amazonaws.com/sitecore-media-bucket/prod%2fen%2f%7b24732AAE-C173-4364-9291-6B7DF79F08A8%7d%3fv%3db98fd032335a551e7f0d041919948606" alt="Sample" />
                                                <a href="#!">
                                                    <div className="mask">
                                                        <img className="img-fluid w-100" src="https://cms.cloudinary.vpsvc.com//image/fetch/q_auto:eco,w_700,f_auto,dpr_auto/https://s3-eu-west-1.amazonaws.com/sitecore-media-bucket/prod%2fen%2f%7b24732AAE-C173-4364-9291-6B7DF79F08A8%7d%3fv%3db98fd032335a551e7f0d041919948606" />
                                                        <div className="mask rgba-black-slight"></div>
                                                    </div>
                                                </a>
                                            </div>
                                            <div class="text-center pt-4">
                                                <h5>Fantasy T-shirt</h5>
                                                <p><span class="mr-1"><strong>$12.99</strong></span></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4 mb-5">
                                        <div>
                                            <div className="view zoom overlay rounded z-depth-2">
                                                <img class="img-fluid w-100" src="https://cms.cloudinary.vpsvc.com//image/fetch/q_auto:eco,w_700,f_auto,dpr_auto/https://s3-eu-west-1.amazonaws.com/sitecore-media-bucket/prod%2fen%2f%7b24732AAE-C173-4364-9291-6B7DF79F08A8%7d%3fv%3db98fd032335a551e7f0d041919948606" alt="Sample" />
                                                <a href="#!">
                                                    <div className="mask">
                                                        <img className="img-fluid w-100" src="https://cms.cloudinary.vpsvc.com//image/fetch/q_auto:eco,w_700,f_auto,dpr_auto/https://s3-eu-west-1.amazonaws.com/sitecore-media-bucket/prod%2fen%2f%7b24732AAE-C173-4364-9291-6B7DF79F08A8%7d%3fv%3db98fd032335a551e7f0d041919948606" />
                                                        <div className="mask rgba-black-slight"></div>
                                                    </div>
                                                </a>
                                            </div>
                                            <div class="text-center pt-4">
                                                <h5>Fantasy T-shirt</h5>
                                                <p><span class="mr-1"><strong>$12.99</strong></span></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </section>



                        </div>

                    </div>

                </div>
            </main>


        </>
    );
}

export default Pens;
