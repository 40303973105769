import './App.css';
import Index from './components/Index';
import Visiting from './components/InnerPages/Visiting';
import Stationery from './components/InnerPages/Stationary';
import Pens from './components/InnerPages/Pens';
import BannersPosters from './components/InnerPages/BannersPosters';
import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

function App() {
  return (
    <div >
      <Router>
        <Switch>
          <Route path="/visiting-cards">
            <Visiting />
          </Route>
          <Route path="/stationery">
            <Stationery />
          </Route>
          <Route path="/personalised-pens">
            <Pens />
          </Route>
          <Route path="/banners-posters">
            <BannersPosters />
          </Route>
          <Route path="/">
            <Index />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}


export default App;
