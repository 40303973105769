import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";


function SubHeader() {

    return (
        <>
            <nav className="shrinktofull navbar navbar-expand-xl navbar-light">

                {/* <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button> */}


                <div id="navbarNav" className="collapse navbar-collapse">
                    <div className="navbar-nav d-xl-flex">

                        {/* <div id="link-2" className="nav-item dropdown-hover">
                            <Link className="nav-link dropdown-hover-button" to="/">All Products<i className="fas fa-caret-down"></i> <span className="sr-only">(current)</span></Link> */}
                            {/* <div className="dropdown-hover-content">
                                <div className="row">
                                    <div className="col-lg-3">
                                        <div className="mb-3">
                                            <h6><a className="colour-2" href="/">Heading 1</a></h6>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-3">
                                            <h6><a className="colour-2" href="/">Heading 2</a></h6>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                        </div>
                                        <div className="mb-3">
                                            <h6><a className="colour-2" href="/">Heading 3</a></h6>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-3">
                                            <h6><a className="colour-2" href="/">Heading 4</a></h6>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                        </div>
                                        <div className="mb-3">
                                            <h6><a className="colour-2" href="/">Heading 5</a></h6>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-3">
                                            <h6><a className="colour-2" href="/">Heading 6</a></h6>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                        </div>
                                        <div className="mb-3">
                                            <h6><a className="colour-2" href="/">Heading 7</a></h6>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        {/* </div> */}
                        {/* part here */}
                        <div id="link-2" className="nav-item dropdown-hover ">
                            <Link className="nav-link dropdown-hover-button" to="/visiting-cards">Visiting Cards<i className="fas fa-caret-down"></i></Link>
                            {/* <div className="dropdown-hover-content">
                                <div className="row">
                                    <div className="col-lg-3">
                                        <div className="mb-2">
                                            <h6><a className="colour-2" href="/">Heading 1</a></h6>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-2">
                                            <h6><a className="colour-2" href="/">Heading 2</a></h6>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-2">
                                            <h6><a className="colour-2" href="/">Heading 3</a></h6>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-2">
                                            <h6><a className="colour-2" href="/">Heading 4</a></h6>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        {/* part here */}

                        <div id="link-2" className="nav-item dropdown-hover ">
                            <Link className="nav-link dropdown-hover-button" to="">Stationery, Letterheads & Stamps<i className="fas fa-caret-down"></i></Link>
                            {/* <div className="dropdown-hover-content">
                                <div className="row">
                                    <div className="col-lg-3">
                                        <div className="mb-2">
                                            <h6><a className="colour-2" href="/">Heading 1</a></h6>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-2">
                                            <h6><a className="colour-2" href="/">Heading 2</a></h6>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-2">
                                            <h6><a className="colour-2" href="/">Heading 3</a></h6>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-2">
                                            <h6><a className="colour-2" href="/">Heading 4</a></h6>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>

                        <div id="link-2" className="nav-item dropdown-hover ">
                            <Link className="nav-link dropdown-hover-button" to="/">Personalised Pens<i className="fas fa-caret-down"></i></Link>
                            {/* <div className="dropdown-hover-content">
                                <div className="row">
                                    <div className="col-lg-3">
                                        <div className="mb-2">
                                            <h6><a className="colour-2" href="/">Heading 1</a></h6>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-2">
                                            <h6><a className="colour-2" href="/">Heading 2</a></h6>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-2">
                                            <h6><a className="colour-2" href="/">Heading 3</a></h6>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-2">
                                            <h6><a className="colour-2" href="/">Heading 4</a></h6>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>


                        <div id="link-2" className="nav-item dropdown-hover ">
                            <a className="nav-link dropdown-hover-button" href="/">Banners, Posters &amp; Signs<i className="fas fa-caret-down"></i></a>
                            {/* <div className="dropdown-hover-content">
                                <div className="row">
                                    <div className="col-lg-3">
                                        <div className="mb-2">
                                            <h6><a className="colour-2" href="/">Heading 1</a></h6>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-2">
                                            <h6><a className="colour-2" href="/">Heading 2</a></h6>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-2">
                                            <h6><a className="colour-2" href="/">Heading 3</a></h6>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-2">
                                            <h6><a className="colour-2" href="/">Heading 4</a></h6>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>

                        <div id="link-2" className="nav-item dropdown-hover ">
                            <a className="nav-link dropdown-hover-button" href="/">Flyers, Folders & Marketing Materials<i className="fas fa-caret-down"></i></a>
                            {/* <div className="dropdown-hover-content">
                                <div className="row">
                                    <div className="col-lg-3">
                                        <div className="mb-2">
                                            <h6><a className="colour-2" href="/">Heading 1</a></h6>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-2">
                                            <h6><a className="colour-2" href="/">Heading 2</a></h6>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-2">
                                            <h6><a className="colour-2" href="/">Heading 3</a></h6>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-2">
                                            <h6><a className="colour-2" href="/">Heading 4</a></h6>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>

                        <div id="link-2" className="nav-item dropdown-hover ">
                            <a className="nav-link dropdown-hover-button" href="/">Labels & Stickers<i className="fas fa-caret-down"></i></a>
                            {/* <div className="dropdown-hover-content">
                                <div className="row">
                                    <div className="col-lg-3">
                                        <div className="mb-2">
                                            <h6><a className="colour-2" href="/">Heading 1</a></h6>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-2">
                                            <h6><a className="colour-2" href="/">Heading 2</a></h6>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-2">
                                            <h6><a className="colour-2" href="/">Heading 3</a></h6>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-2">
                                            <h6><a className="colour-2" href="/">Heading 4</a></h6>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>

                        <div id="link-2" className="nav-item dropdown-hover ">
                            <a className="nav-link dropdown-hover-button" href="/">Clothing and Bags<i className="fas fa-caret-down"></i></a>
                            {/* <div className="dropdown-hover-content">
                                <div className="row">
                                    <div className="col-lg-3">
                                        <div className="mb-2">
                                            <h6><a className="colour-2" href="/">Heading 1</a></h6>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-2">
                                            <h6><a className="colour-2" href="/">Heading 2</a></h6>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-2">
                                            <h6><a className="colour-2" href="/">Heading 3</a></h6>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-2">
                                            <h6><a className="colour-2" href="/">Heading 4</a></h6>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>

                        <div id="link-2" className="nav-item dropdown-hover ">
                            <a className="nav-link dropdown-hover-button" href="/">Mug, Albums & Gifts<i className="fas fa-caret-down"></i></a>
                            {/* <div className="dropdown-hover-content">
                                <div className="row">
                                    <div className="col-lg-3">
                                        <div className="mb-2">
                                            <h6><a className="colour-2" href="/">Heading 1</a></h6>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-2">
                                            <h6><a className="colour-2" href="/">Heading 2</a></h6>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-2">
                                            <h6><a className="colour-2" href="/">Heading 3</a></h6>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-2">
                                            <h6><a className="colour-2" href="/">Heading 4</a></h6>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>

                        <div id="link-2" className="nav-item dropdown-hover ">
                            <a className="nav-link dropdown-hover-button" href="/">Face Masks, COVID related<i className="fas fa-caret-down"></i></a>
                            {/* <div className="dropdown-hover-content">
                                <div className="row">
                                    <div className="col-lg-3">
                                        <div className="mb-2">
                                            <h6><a className="colour-2" href="/">Heading 1</a></h6>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-2">
                                            <h6><a className="colour-2" href="/">Heading 2</a></h6>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-2">
                                            <h6><a className="colour-2" href="/">Heading 3</a></h6>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-2">
                                            <h6><a className="colour-2" href="/">Heading 4</a></h6>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>

                        <div id="link-3" className="nav-item dropdown-hover ">
                            <a className="nav-link dropdown-hover-button" href="/">New Arrivals<i className="fas fa-caret-down"></i></a>
                            {/* <div className="dropdown-hover-content">
                                <div className="row">
                                    <div className="col-lg-3">
                                        <div className="mb-2">
                                            <h6><a className="colour-2" href="/">Heading 1</a></h6>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-2">
                                            <h6><a className="colour-2" href="/">Heading 2</a></h6>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-2">
                                            <h6><a className="colour-2" href="/">Heading 3</a></h6>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-2">
                                            <h6><a className="colour-2" href="/">Heading 4</a></h6>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                            <a className="dropdown-item p-0" href="/">Sub Page</a>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>


                    </div>



                </div>
            </nav>
        </>
    );
}

export default SubHeader;