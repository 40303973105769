import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';   
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { faHeadphones } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

function Topbar() {
    return (
        <div className="middle-header dark_skin">
            <div className="noti-header" style={{ width: "100%", height: "30px", background: "#38454F", marginBottom: "1%" }}><p style={{ color: "white", display: "flex", fontSize: "14px", justifyContent: "center", paddingTop: "4px"}} >We always welcome your enquiries. Give us a call at &nbsp;<u><a style={{ color: "white"}} href="tel:9810214753">9810214753</a></u></p></div>
            <div className="shrinktofull custom-container">
                <div className="nav_block">
                    <Link className="navbar-brand" to="/">
                        <img style={{ width: "50%" }} className="logo_dark" src="/assets/images/Logo.png" alt="logo" />
                    </Link>
                    <div className="product_search_form rounded_input">
                        <form>
                            <div className="input-group">
                                <input className="form-control" placeholder="Search Product..." required="" type="text" />
                                <button type="submit" className="search_btn2"><FontAwesomeIcon icon={faSearch} /></button>
                            </div>
                        </form>
                    </div>
                    <ul className="navbar-nav attr-nav align-items-center">
                        <li className="header-link header-link-phone"><FontAwesomeIcon className="header-link-icon" icon={faHeadphones} /><span className="header-link-text">
                            <span className="header-link-text-top text-large">
                                Help is here.
                             </span>
                            <span className="header-link-text-bottom">
                                9810214753
                                </span>
                        </span></li>
                        {/* <li className="header-link header-link-phone"><FontAwesomeIcon className="header-link-icon" icon={faUser} /><span className="header-link-text">
                            <span className="header-link-text-top text-large">
                                Sign In
                                </span>
                            <span className="header-link-text-bottom">
                               My Account
                             </span>
                        </span></li>
                        <li className="header-link header-link-phone"><FontAwesomeIcon className="header-link-icon" icon={faShoppingCart} /><span className="header-link-text">
                            <span className="header-link-text-top text-large">
                            Cart
                                </span>
                            
                        </span>
                        </li> */}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Topbar;
