import React from 'react';
import '../App.css';
import Header from './layouts/Header';
import NewLaunch from './NewLaunches';
import MoreToExplore from './MoreToExplore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { faMailBulk } from '@fortawesome/free-solid-svg-icons';
import { faRupeeSign } from '@fortawesome/free-solid-svg-icons';


function Index() {

    return (
        <>
            <Header />
            <div style={{ textAlign: "center" }} className="shrinktofull">
                <div className="hero-image" style={{ backgroundImage: `url(assets/images/bannee1.jpg)` }}></div>
                <div className="brand-banner">
                    <div style={{ marginTop: "30px" }} className="grid-container row col-md-12">
                        <div className="col-md-4 " data-analytics-section="Brand banner 1">
                            <a onClick={(e) => e.preventDefault()} className="icon-tile icon-tile-align-left" data-analytics-click="tile-icon" data-analytics-id="">
                                <div className="icon-tile-icon">
                                    <FontAwesomeIcon style={{ fontSize: "50px", color: "#024687" }} icon={faShoppingCart} />
                                </div>
                                <div className="icon-tile-heading">
                                    Even Low Quantities @ Best Prices
                                </div>
                            </a>

                        </div>
                        <div className="col-md-4 " data-analytics-section="Brand banner 2">
                            <a onClick={(e) => e.preventDefault()} className="icon-tile icon-tile-align-left" data-analytics-click="tile-icon" data-analytics-id="">
                                <div className="icon-tile-icon">
                                    <FontAwesomeIcon style={{ fontSize: "50px", color: "#024687" }} icon={faMailBulk} />

                                </div>
                                <div className="icon-tile-heading">
                                    Many Products and Designs to choose from
                                </div>
                            </a>

                        </div>
                        <div className="col-md-4 " data-analytics-section="Brand banner 3">
                            <a onClick={(e) => e.preventDefault()} className="icon-tile icon-tile-align-left" data-analytics-click="tile-icon" data-analytics-id="">
                                <div className="icon-tile-icon">
                                    <FontAwesomeIcon style={{ fontSize: "50px", color: "#024687" }} icon={faRupeeSign} />

                                </div>
                                <div className="icon-tile-heading">
                                    100% Satisfaction for price
                                </div>
                            </a>

                        </div>

                    </div>

                </div>

                {/* grid containers */}
                <div className="grid-container grid-container-line-wrap">

                    <div className="row">
                        <div className="col-12">
                            <h2 className="header-divider text-size-4 " id="featuredproductsheaderdivider">
                                <div className="header-divider-text">
                                    FEATURED PRODUCTS
                                </div>
                            </h2>
                        </div>
                        <div className="col-4 col-xs-6">
                            <div className="standard-product-tile standard-product-tile-vertical">
                                <div className="callout-bar discount-flag callout-bar-skin-deep-blue">
                                    <span>100 @ Rs.170</span>
                                </div>
                                <div className="standard-product-tile-image">
                                    <a onClick={(e) => e.preventDefault()}>
                                        <div className="responsive-image" style={{ paddingBottom: "100%" }}><img alt=""
                                            src="/assets/images/visitingcard1.jpeg" />
                                        </div>
                                    </a>
                                </div><a onClick={(e) => e.preventDefault()}>
                                    <p className="standard-product-tile-name" data-analytics-click="tile-header" data-analytics-id="GMD">
                                        Standard Visiting Cards
                                    </p>
                                </a>
                            </div>
                        </div>

                        <div className="col-4 col-xs-6 ">
                            <div className="standard-product-tile standard-product-tile-vertical">
                                <div className="callout-bar discount-flag callout-bar-skin-deep-blue">
                                    <span>100 @ Rs.225</span>
                                </div>
                                <div className="standard-product-tile-image">
                                    <a onClick={(e) => e.preventDefault()}>
                                        <div className="responsive-image" style={{ paddingBottom: "100%" }}>
                                            <img alt="Front-Back Visiting Cards" src="/assets/images/classicCards.jpeg" />
                                        </div>
                                    </a>
                                </div>
                                <a href onClick={(e) => e.preventDefault()}>
                                    <p className="standard-product-tile-name" data-analytics-click="tile-header" data-analytics-id="GMD">
                                        Classic Visiting Cards
                                    </p>
                                </a>
                            </div>

                        </div>


                        <div className="col-4 col-xs-6">
                            <div className="standard-product-tile standard-product-tile-vertical">
                                <div className="callout-bar discount-flag callout-bar-skin-deep-blue">
                                    <span>100 @ Rs.275</span>
                                </div>
                                <div className="standard-product-tile-image">
                                    <a href onClick={(e) => e.preventDefault()}>
                                        <div className="responsive-image" style={{ paddingBottom: "100%" }}><img alt="SVC--COVID"
                                            src="/assets/images/roundedvisitingcard.jpeg"
                                        />
                                        </div>
                                    </a>
                                </div><a onClick={(e) => e.preventDefault()}>
                                    <p className="standard-product-tile-name" data-analytics-click="tile-header" data-analytics-id="GMD">
                                        Rounded Visiting Cards
                                    </p>
                                </a>
                            </div>
                        </div>


                        <div className="col-4 col-xs-6">
                            <div className="standard-product-tile standard-product-tile-vertical">
                                <div className="callout-bar discount-flag callout-bar-skin-deep-blue">
                                    <span>100 @ Rs.500</span>
                                </div>
                                <div className="standard-product-tile-image">
                                    <a onClick={(e) => e.preventDefault()}>
                                        <div className="responsive-image" style={{ paddingBottom: "100%" }}><img alt="Rounded visiting cards"
                                            src="/assets/images/letter-heads.jpeg" />
                                        </div>
                                    </a>
                                </div><a
                                    onClick={(e) => e.preventDefault()}>
                                    <p className="standard-product-tile-name" data-analytics-click="tile-header" data-analytics-id="CU0">
                                        Letterheads
                                    </p>
                                </a>
                            </div>

                        </div>

                        <div className="col-4 col-xs-6">


                            <div className="standard-product-tile standard-product-tile-vertical">
                                <div className="callout-bar discount-flag callout-bar-skin-deep-blue">
                                    <span>1 @ Rs. 300</span>
                                </div>
                                <div className="standard-product-tile-image">
                                    <a onClick={(e) => e.preventDefault()}>
                                        <div className="responsive-image" style={{ paddingBottom: "100%" }}><img alt="Rounded visiting cards"
                                            src="/assets/images/t-shirts.jpeg"
                                        />
                                        </div>
                                    </a>
                                </div><a
                                    onClick={(e) => e.preventDefault()}>
                                    <p className="standard-product-tile-name" data-analytics-click="tile-header" data-analytics-id="CU0">
                                        T shirts
                                    </p>
                                </a>
                            </div>

                        </div>

                        <div className="col-4 col-xs-6">


                            <div className="standard-product-tile standard-product-tile-vertical">





                                <div className="callout-bar discount-flag callout-bar-skin-deep-blue">
                                    <span>1 @ Rs. 70</span>
                                </div>
                                <div className="standard-product-tile-image">
                                    <a onClick={(e) => e.preventDefault()}>
                                        <div className="responsive-image" style={{ paddingBottom: "100%" }}><img alt="Rounded visiting cards"
                                            src="/assets/images/i-cards.jpeg"
                                        />
                                        </div>
                                    </a>
                                </div><a
                                    onClick={(e) => e.preventDefault()}>
                                    <p className="standard-product-tile-name" data-analytics-click="tile-header" data-analytics-id="CU0">
                                        Identity Cards
                                    </p>
                                </a>
                            </div>

                        </div>

                        {/* new launched products */}
                        {/* <NewLaunch /> */}
                        {/* more to explore */}
                        {/* <MoreToExplore /> */}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Index;