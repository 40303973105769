import React from 'react';
import Topbar from './Topbar'
import SubHeader from './SubHeader'

function Header() {
  return (
    <>
   
      <Topbar />
      <SubHeader />
      
    </>
  );
}

export default Header;
